<template>
  <div>
    <transition name="fade">
      <CCard v-if="show" color="">
        <CCardHeader>
          {{ first_title }} School
          <div class="card-header-actions">
            <!-- <CLink
              class="card-header-action btn-minimize"
              @click="isCollapsed = !isCollapsed"
            >
              <CIcon :name="`cil-chevron-${isCollapsed ? 'bottom' : 'top'}`" />
            </CLink>
            <CLink
              href="#"
              class="card-header-action btn-close"
              v-on:click="show = false"
            >
              <CIcon name="cil-x" />
            </CLink> -->
          </div>
        </CCardHeader>
        <CCollapse :show="isCollapsed" :duration="400">
          <CCardBody>
            <div class="form-group row">
              <label
                for="teacherNameFormControlSelect"
                class="col-sm-4 col-form-label"
                >District Name</label
              >
              <div class="col-sm-8">
                <select class="form-control" v-model="form.district">
                  <option value="">Select District</option>
                  <option value="1">Mumbai</option>
                  <option value="2">Pune</option>
                </select>
              </div>
            </div>
            <!-- end form-group -->

            <div class="form-group row">
              <label
                for="classFormControlSelect"
                class="col-sm-4 col-form-label"
                >School Name</label
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  name="school"
                  class="form-control"
                  placeholder="School Name"
                  v-model="form.schoolName"
                />
              </div>
            </div>
            <!-- end form-group -->

            <div class="form-group row">
              <label
                for="subjectFormControlSelect"
                class="col-sm-4 col-form-label"
                >Address</label
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  name="school"
                  class="form-control"
                  placeholder="School Address"
                  v-model="form.schoolAddress"
                />
              </div>
            </div>
            <!-- end form-group -->

            <div class="form-group row">
              <label
                for="divisionFormControlSelect"
                class="col-sm-4 col-form-label"
                >Contact Person</label
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  name="school"
                  class="form-control"
                  placeholder="Contact Person Name"
                  v-model="form.contactPerson"
                />
              </div>
            </div>
            <!-- end form-group -->

            <div class="form-group row">
              <label for="yearFormControlSelect" class="col-sm-4 col-form-label"
                >Contact No.</label
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  name="school"
                  class="form-control"
                  placeholder="Contact No."
                  v-model="form.contact"
                />
              </div>
            </div>
            <!-- end form-group -->
            <div class="form-group row">
              <div class="col-sm-8 offset-sm-4">
                <button
                  type="submit"
                  class="btn btn-primary mr-3 text-white"
                  @click="onSubmit1"
                >
                  Submit
                </button>
                <button type="submit" class="btn btn-light">
                  Reset
                </button>
              </div>
            </div>
          </CCardBody>
        </CCollapse>
      </CCard>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import apis from "@/apis";
const base_url =
  "https://3t23rkamvh.execute-api.ap-south-1.amazonaws.com/Prod/api";

export default {
  name: "AddTeacher",
  components: {},
  data: function() {
    return {
      form: {
        userName: "",
        params: "",
        district: "",
        schoolName: "",
        schoolAddress: "",
        contactPerson: "",
        contact: "",
        password: "",
        checked: [],
      },
      show: true,
      isCollapsed: true,
      teacher_list: [],
      class_list: [],
      division_list: [],
      subject_list: [],
      year_list: [],
      teacher: "",
      class_id: "",
      division: "",
      year: "",
      subject: "",
      para: [],
      first_title: "",
    };
  },
  methods: {
    onSubmit1() {
      alert("here");
      //event.preventDefault();
      if (this.para == "" || this.para == undefined) {
        var data = {
          school_name: this.form.schoolName,
          address: this.form.schoolAddress,
          contact_no: this.form.contact,
          contact_person: this.form.contactPerson,
          district_id: "00000000-0000-0000-0000-000000000000",
        };
        console.log(data);
        var promise = apis
          .Add_Schools(data)
          .then((response) => {
            this.$alert("School Added Sucessfully");
            this.$router.go(-1);
          })
          .catch((error) => {});
      } else {
        var data = {
          school_id: this.id,
          school_name: this.form.schoolName,
          address: this.form.schoolAddress,
          contact_no: this.form.contact,
          contact_person: this.form.contactPerson,
          district_id: "00000000-0000-0000-0000-000000000000",
        };
        console.log(data);
        axios
          .put(
            "https://3t23rkamvh.execute-api.ap-south-1.amazonaws.com/Prod/api/Schools/" +
              this.id,
            data
          )
          .then((response) => {
            this.isLoading = false;

            this.$alert("School Updated Sucessfully");
            this.$router.go(-1);
          })
          .catch((error) => {});
      }
    },
    get_teacher() {
      var promise = apis
        .list_Teachers()
        .then((response) => {
          this.teacher_list = response.data;
        })
        .catch((errror) => {});
    },
    get_class() {
      var promise = apis
        .list_ClassMasters()
        .then((response) => {
          this.class_list = response.data;
        })
        .catch((errror) => {});
    },
    get_year() {
      var promise = apis
        .list_AcademicYears()
        .then((response) => {
          this.year_list = response.data;
        })
        .catch((errror) => {});
    },
    get_subject() {
      var promise = apis
        .list_Subjects()
        .then((response) => {
          this.subject_list = response.data;
        })
        .catch((errror) => {});
    },
    get_division() {
      var promise = apis
        .list_DivisionMasters()
        .then((response) => {
          this.division_list = response.data;
        })
        .catch((errror) => {});
    },
    // save_data(){
    //   var data = {
    //     teacher_name: this.teacher,

    //   }
    //    var promise = apis.add_Teachers(data).then( response => {
    //       alert("Data Saved");
    //   }).catch( errror => {

    //   })
    // }
  },
  created() {
    this.get_subject();
    this.get_teacher();
    this.get_division();
    this.get_class();
    this.get_year();
    this.para = this.$route.params.items;
    if (this.para == "" || this.para == undefined) {
      this.first_title = "Add New";
    } else {
      this.first_title = "Edit";
    }
    console.log(this.para);
    this.id = this.para.school_id;
    this.form.schoolName = this.para.school_name;
    // this.form.district = this.para.address;
    this.form.schoolAddress = this.para.address;
    this.form.contactPerson = this.para.contact_person;
    this.form.contact = this.para.contact_no;
  },
};
</script>
